<template>
  <div id="appSceneBox" class="app-scene-wrap">
    <div class="kad-container">
      <div class="kad-card-la-title">公司介绍</div>
      <div class="kax-box">
        <div class="kax-box-content d-flex">
          <div class="kax-box-content-text vert-al-top">
            <!-- <p>
              <span class="stress-cell">深圳市带塔云科技有限公司</span
              >是一家专注于亚马逊数据分析服务的科技公司，公司成立于2021年5月25日。
            </p> -->
            <p class="mb5">
              <span class="stress-cell">深圳市带塔云科技有限公司</span
              >是一家专注于为企业数据分析服务的科技公司，公司成立于2021年5月25日。
            </p>
            <p>
              <span class="stress-cell"></span
              >公司的使命是希望用科技的力量推动发展，助力企业降本增效！
            </p>
          </div>
          <div class="iocn flex-align-center">
            <img src="../../fonts/logo.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="kad-container">
      <div class="kad-card-la-title">产品介绍</div>
      <div class="card-titles">
        <p>暗度希望帮助广大卖家低成本、快速高效地找到优质的、长期在亚马逊平台无店铺销售的链接(最少超过2年以上的闲置链接)！</p>
        <p class="cetexs">暗度就是一个查找闲置链接/僵尸链接的一个工具，平台已帮助卖家收录了全美超过1000万条优质闲置链接！ </p>
      </div>
      <div class="unused">
        <div class="unused-left">
          <div class="idle">
            <div>什么是闲置链接？</div>
            <p>
              <span
                >长期在亚马逊平台无店铺销售的链接，也就是无主链接，俗称僵尸链接。</span
              >
            </p>
            <p>
              <span
                >其实早在2017-2018年的时候，就已经出现过此类技术了，只不过今年亚马逊关于操控评价LU品牌的情况搞得太严重了，然后沉寂了很久的僵尸链接又被推了出来，亚马逊圈内目前流传的僵尸链接又成为新的焦点。</span
              >
            </p>
            <p>
              <span
                >暗度帮大家解决了这个问题，不仅能帮卖家快速找到亚马逊平台的优质闲置链接，而且服务价格还非常非常的便宜！</span
              >
            </p>
          </div>
        </div>
        <div class="unused-right">
          <div class="idle2">
              <div>闲置链接来源</div>
              <p><span>1、店铺主动停止销售，listing突然退出舞台，这种listing是非常好的；</span></p>
              <p><span>2、产品更新换代不卖，卖家转行不做的或者直接下架的链接；</span></p>
              <p><span>3、被亚马逊识别有风险ASIN，短期冲上去赚一波就不卖了的；</span></p>
              <p><span>4、卖家认为无价值ASIN或者评分低于3颗星不卖了的；</span></p>
              <p><span>5、店铺被停掉销售权、listing不让卖或者被close掉、店铺申诉期间等等的链接。</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "app-scene",
  components: {},
  props: {},
  data() {
    return {
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.kad-container{
    width: 1026px;
}
.kax-box{
    margin-bottom: 40px;
}
.kax-box-content-text {
  width: 66%;
  display: inline-block;
//   font-size: 20px;
  font-family: Microsoft YaHei;
  color: #373e4f;
  line-height: 30px;
  margin-right: 85px;
  .cetexs {
    margin: 10px 0;
  }
  .stress-cell{
      font-size: 20px;
  }
p{
      font-size: 16px;
  }
}
.iocn {
  width: 25%;
  // display: inline-block;
  img {
    width: 210px;
    height: 46px;
    // margin-bottom: 70px;
  }
}
.card-titles {
  font-size: 16px;
  color: #373e4f;
  line-height: 30px;
  margin-bottom: 40px;
  span {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
  }
}
.unused {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  .unused-left {
    flex: 1;
    .idle {
      padding: 30px;
      width: 485px;
      height: 460px;
      // background: linear-gradient(0deg, #EFF5FF, #F7FAFF);
      box-shadow: 0px 2px 8px 0px rgba(198, 205, 228, 0.3);
      border-radius: 4px;
      background-image: url("../../assets/home/imges1.png");
      background-size: 100%; /*按比例缩放*/
      background-repeat: no-repeat; /*还有repeat-x,y等*/
      div {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #373e4f;
        margin-bottom: 30px;
      }
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #373e4f;
        line-height: 30px;
      }
    }
  }
  .unused-right {
    flex: 1;
    .idle2 {
         padding: 30px;
      width: 485px;
      height: 460px;
      // background: linear-gradient(0deg, #EFF5FF, #F7FAFF);
      box-shadow: 0px 2px 8px 0px rgba(198, 205, 228, 0.3);
      border-radius: 4px;
      background-image: url("../../assets/home/imges2.png");
      background-size: 100%; /*按比例缩放*/
      background-repeat: no-repeat; /*还有repeat-x,y等*/
            div {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #373e4f;
        margin-bottom: 30px;
      }
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #373e4f;
        line-height: 30px;
      }
    }
  }
}
</style>
