<template>
  <div>
    <headers />
    <div class="bg-box">
      <img src="../../assets/home/banertu2.png" alt="" />
      <!-- <div class="textes">
        <span>公司理念</span><br/>
        <span>希望用专业、极致的服务，用数据助力企业腾飞</span>
      </div> -->
    </div>
    <div class="banner-wrap">
        <Texts/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Headers from "@/components/Header/index.vue";
import Texts  from "./texts.vue"
import Footer  from "../home/components/Footer.vue"
export default {
  components: {
    Headers,
    Texts,
    Footer
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.bg-box {
  width: 100%;
  margin: 80px 0 0px;
  img {
    width: 100%;
    // height: 100%;
  }
  .textes {
    position: absolute;
    top: 20%;
    left: 20%;
    font-size: 30px;
    color: #fff;
    line-height: 55px;
  }
}
.banner-wrap{
margin-top: -30px;
  display: flex;
  justify-content: center;
}
</style>
